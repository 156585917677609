import { Component, HostListener, ChangeDetectorRef, OnInit, Inject, APP_INITIALIZER, ApplicationInitStatus  } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive'
import { NavController, Platform } from '@ionic/angular';
import { ToolsService } from './services/tools.service';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from './services/navigation.service';
import { GetterService } from './getter.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  // some fields to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  idleTime: any;
  mobile = false
  
  constructor(public platform: Platform, private translate: TranslateService, private router: Router, public tools: ToolsService, private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef,
    @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus, public navigation: NavigationService, public navCtrl: NavController, public getter: GetterService, public stats: StatisticsService) {
      this.navigation.startSaveHistory();
      this.translate.setDefaultLang('es');
      this.translate.use('es');

      //idle.setIdle(5); // how long can they be inactive before considered idle, in seconds
      //idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
      //idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

      idle.stop();
      console.log("IDLE Stopped")
      platform.ready().then(() => {
        this.tools.mobile = false;
          if (platform.width() <= 600){
            this.tools.mobile = true;
          }
      });
      this.mobile = this.platform.is('ios') || this.platform.is('android') ? true : false;

      // do something when the user becomes idle
      idle.onIdleStart.subscribe(() => {
        this.idleState = "IDLE";
      });

      // do something when the user is no longer idle
      idle.onIdleEnd.subscribe(() => {        
        this.idleState = "NOT_IDLE";
        console.log(`${this.idleState} ${new Date()}`)
        this.countdown = null;
        cd.detectChanges(); // how do i avoid this kludge?
      });

      // do something when the user has timed out
      idle.onTimeout.subscribe(() => {
        this.goStandBy();
      } );

      // do something as the timeout countdown does its thing
      idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

      // set keepalive parameters, omit if not using keepalive
      keepalive.interval(15); // will ping at this interval while not idle, in seconds
      keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
      //idle.stop();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 850){
      this.tools.mobile = true;
    }
    if(event.target.innerWidth > 850){
      this.tools.mobile = false;
    }
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    //Test BEFORE UNLOAD STATS CUADRO CIERRE
    //this.subscribeToNativeNavigation();
    const root = document.querySelector(':root') as HTMLElement;
    root.style.setProperty('--main-color', '#11B7B7');
    root.style.setProperty('--main-color-strong', '#13505B');
    this.reset(); 
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationStart) {
        console.log(event);
      }
    });
  }

  private subscribeToNativeNavigation() {
    fromEvent(window, 'beforeunload')
    .subscribe((e) => {
      const message = 'Sending data';
      (e || window.event).returnValue = !message;
      return this.stats.setSesh();
      navigator.sendBeacon('/log', message);
      return message;
      })
  }

  ngAfterViewInit(){
    console.log("init working");
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('beforeinstallprompt Event fired');
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.getter.deferredPrompt = e;
      this.getter.showBanner = true;
    });
  }

  resetTimeout(){
    this.reset();
  }

  goStandBy(){
    this.stats.setSesh();
    this.getter.route = null;
    console.log("Timed Out");
    this.idleState = "TIMED_OUT";
    //this.stats.initStats();
    if (this.getter.standby){
      //localStorage.clear();
      //this.router.navigate(["/standby"])
      this.navCtrl.navigateRoot(this.getter.standBys.length > 0 ? '/standby' : '/start', { animated: true, animationDirection: 'forward' });
    }
  }
  
}