import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { GetterService } from '../getter.service';

export interface Statistics{
    clientId: number,
    deviceId: number,
    sessionTime: number,
    language: string,
    points: string[],
    events: string[],
    pdfs: string[],
    travelerType: string,
    categories: string,
    subCategories: string,
    deviceType: number
}

@Injectable({
    providedIn: 'root'
})

export class StatisticsService {
    sesh:Statistics;
    sessionTime: number;
    intvl: any;
    clientId: number;
    deviceId: number;
    points: any[];
    events: any[];
    pdfs: any[];
    travelerType: any;
    categories: any;
    subCategories: any;
    
    constructor(public http: HttpClient, public translate: TranslateService, public getter: GetterService) {}

    initStats(){
        console.log("Reiniciando Stats")
        clearInterval(this.intvl);
        this.sessionTime = 0;
        this.points = [];
        this.events = [];
        this.pdfs = [];
        this.travelerType = null;
        this.categories = null;
        this.subCategories = null;
        this.intvl = setInterval(() => {
            this.sessionTime++
        }, 1000);
    }

    addPoint(pointID){
        this.points.push(pointID);
    }

    addEvent(eventID){
        this.events.push(eventID);
    }

    addPdf(pdfID){
        this.pdfs.push(pdfID);
    }

    setInterest(interestArray){
        this.categories = interestArray;
    }

    setTravelerType(howTravel){
        this.travelerType = howTravel;
    }

    setSubInterest(subInterestArray){
        this.subCategories = subInterestArray;
    }

    setSesh(){
        this.sesh = {
            clientId: this.getter.clientID,
            deviceId: this.getter.deviceID,
            deviceType: 1,
            sessionTime: this.sessionTime,
            points: this.points.length > 0 ? this.points : null,
            events: this.events.length > 0 ? this.events : null,
            pdfs: this.pdfs.length > 0 ? this.pdfs : null,
            travelerType: this.travelerType,
            categories: this.categories,
            subCategories: this.subCategories,
            language: this.translate.currentLang,
        };

        const testFunction = this;
        this.sendStats(this.sesh).subscribe(response => {
            console.log("Estadisticas enviadas: ", response);
            testFunction.initStats();
        });
    }

    public sendStats(sesh){
        return this.http.post(`${this.getter.apiUrlBase}/api/send-stats`, { sesh });
    }

}