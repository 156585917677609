import { Injectable } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { GetterService } from '../getter.service'
import { NavController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private history: string[] = []

  constructor(private router: Router, public getter: GetterService, public navCtrl: NavController) { }

  public startSaveHistory():void{
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length == 0){
          this.history.push(event.urlAfterRedirects)
        }else{
          if (!event.urlAfterRedirects.includes('iframe') && this.history[this.history.length-1] != event.urlAfterRedirects){
              this.history.push(event.urlAfterRedirects)
          }
        }
      }
    })
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {
    this.history.pop();
    if (this.history.length > 1) {
      //this.router.navigateByUrl(this.history.pop())
      this.navCtrl.navigateRoot(this.history.pop(), { animated: true, animationDirection: 'forward' });
    } else {
      //this.router.navigateByUrl(this.getter.redirect)
      this.navCtrl.navigateRoot(this.getter.redirect, { animated: true, animationDirection: 'forward' });
    }
  }

  public getPreviousUrl(): string {
    if (this.history.length > 0) {
        return this.history[this.history.length - 2];
    }
    return 'aa';
  }

}