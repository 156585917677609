import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class GetterService {
  demo = false;
  apiUrlBase: any;
  noParams: any;
  extras: any;
  extrasList: any;
  app:any;
  pointsOfInterest: any;
  route:any;
  registerAfter: any;
  registerBefore: any;
  filteredCategories: any;
  routeHowFilter: any;
  routeTypeFilter: any;
  firstPointSelected: any;
  pdfs: any;
  menuPdfs: any;
  client: any;
  clientID: any;
  attributes: any;
  customRoutes: any;
  selectedPDFs: any;
  infoClient: any;
  weatherFake: any;
  provinces: any;
  filteredHome: any;
  idCicerone: any;
  subCategoriesPage: any;
  redirect: any;
  closedPrompt = false;
  showBanner = false;
  filteredSubCategories: any;
  deferredPrompt: any;
  selectedLocation: any;
  totemDesign: any;
  isTotem: any;
  standby: any;
  mac: any;
  languages: any;
  slider: any;
  headerDesc: any;
  sendRoutePDF: any;
  headerName: any;
  homePoints: any;
  events: any;
  travelerType: any;
  tags: any;
  colorCorp1: any;
  colorCorp2: any;
  mainLanguage: any;
  selfieMasks: any;
  imgRecommendedRoutes: any;
  recommendedRoutes: any;
  imgRouteConfig: any;
  streetmap: any;
  imgStreetMap: any;
  typePointsInterest: any;
  timeToEnterStandBy: any;
  backgroundColor: any;
  logo: any;
  standBys: any;
  prefix: any;
  deviceID: any;
  rss: any;
  routeCategories: any;
  email: any;
  idRoute: any;
  idSurvey: any;
  survey: any;
  appStarted = false;
  updateMode = false;
  showRegisterRecommendedRoute: any;

  constructor(private router: Router, public navCtrl: NavController, private translate: TranslateService) {
    this.apiUrlBase = 'https://demo-back.cms-iurban.com';
    //this.apiUrlBase = 'http://cicerone-back.test';
    this.route = null;
    this.selectedPDFs = null;
    this.infoClient = null;
    this.standby = false;
    this.isTotem = false;
    this.idRoute = null;
    this.recommendedRoutes = [];
    this.showRegisterRecommendedRoute = false;
  }

  checkNewData(){
    if (!this.appStarted){
      const url = localStorage.getItem('url')      
      window.location.href = url ? JSON.parse(url) : window.location.origin;
    }
  }

  public setExtras(data){
    const time = 1000 * 60 * 60 * 24;
    this.setWithExpiry('extras', data, time)
    this.extras = data;
  }

  public getExtras(){
    return this.extras ? this.extras : this.getWithExpiry('extras');
  }

  public setExtrasList(data){
    const time = 1000 * 60 * 60 * 24;
    this.setWithExpiry('extrasList', data, time)
    this.extrasList = data;
  }

  public getExtrasList(){
    return this.extrasList ? this.extrasList : this.getWithExpiry('extrasList');
  }

  //---------------------------------------------------------- SELECCION IDIOMA  ---------------------------------------------------------------------
  changeLanguage(lang){
      console.log("Changing to Lang: ", lang)
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------------ REDIRECCION  ------------------------------------------------------------------------

  goToURL(url, item = null){
    if (item){
      this.setExtras(item);
    }
    this.navCtrl.navigateRoot(url, { animated: true, animationDirection: 'forward' });
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------------ ERROR IMAGEN  -----------------------------------------------------------------------
  onImageError(e){
    e.target.src = "/assets/imgs/imageDefault.png";
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------------ANIMACIONES CSS ----------------------------------------------------------------------
  animateCSS = (element, animation, prefix = 'animate__') =>
  new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element);
      if (node){
          node.classList.add(`${prefix}animated`, animationName);
          function handleAnimationEnd(event) {
              event.stopPropagation();
              node.classList.remove(`${prefix}animated`, animationName);
              resolve('Animation ended');
          }
          node.addEventListener('animationend', handleAnimationEnd, {once: true});
      }
  });
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------ESTILOS BARRA DESPLAZAMIENTO ----------------------------------------------------------------
  styleScrollbars(elmt: any) {
    const stylesheet = `
    ::-webkit-scrollbar {
      width: 10px !important;
    }
    
    ::-webkit-scrollbar-track {
      background: ${this.colorCorp1} !important; 
      --background: ${this.colorCorp1} !important; 
    }
      
    ::-webkit-scrollbar-thumb {
      background: ${this.colorCorp2} !important;
      --background: ${this.colorCorp1} !important; 
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: ${this.colorCorp2} !important;
      --background: ${this.colorCorp1} !important; 
    }

    @media only screen and (max-width: 600px) { 
      ::-webkit-scrollbar {
        display: none;
      }
    }
    `;
    const styleElmt = elmt.shadowRoot.querySelector('style');
    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      elmt.shadowRoot.appendChild(barStyle);
    }
  }
  //------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------BOTONES MOVIMIENTOS CARDS ----------------------------------------------------------------
  checkNavigationButton(){
    const test = Array.from(document.getElementsByClassName('goRightPDF') as HTMLCollectionOf<HTMLElement>);
      test.forEach(element => {
        const item = element.closest('.sectionPDFs').querySelector('.sendRoutePDF');
        if (item && item.scrollWidth > item.clientWidth){
          element.style.opacity = '1';
        }
      });
  }
  
  goLeft(e){
    const item = e.target;
    const cards = item.closest('.sectionPDFs').getElementsByClassName('sendRoutePDF');
    if (cards && cards.length > 0){
      const value = window.innerWidth / 2 > 600 ? window.innerWidth /2 : window.innerWidth -100;
      cards[0].scrollLeft=cards[0].scrollLeft -= window.innerWidth / 2 > 600 ? window.innerWidth /2 : window.innerWidth -100
      const rightArrow = item.closest('.sectionPDFs').querySelector('.goRightPDF');
      rightArrow.style.opacity = '1'
    }    
    }

  goRight(e){
    const item = e.target;
    const cards = item.closest('.sectionPDFs').getElementsByClassName('sendRoutePDF');
    if (cards && cards.length > 0){
      const value = window.innerWidth / 2 > 600 ? window.innerWidth /2 : window.innerWidth -100;
      cards[0].scrollLeft = cards[0].scrollLeft += window.innerWidth / 2 > 600 ? window.innerWidth /2 : window.innerWidth -100
      const leftArrow = item.closest('.sectionPDFs').querySelector('.goLeftPDF');
      leftArrow.style.opacity = '1'
    }    
  }

  scrollPDF(e){
    const item = e.target;
    const left = item.closest('.sectionPDFs').getElementsByClassName('goLeftPDF')[0];
    const right = item.closest('.sectionPDFs').getElementsByClassName('goRightPDF')[0];
    const cards = item.closest('.sectionPDFs').getElementsByClassName('sendRoutePDF');
    left.style.opacity = '1';
    right.style.opacity = '1';
    if (cards && cards.length > 0){
      if ((cards[0].scrollLeft + cards[0].clientWidth) >= cards[0].scrollWidth){
        right.style.opacity = '0';
      }
      if (cards[0].scrollLeft <= 0){
        left.style.opacity = '0';
      }
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------------------ TEMP LOCAL STORAGE ------------------------------------------------------------------
  setWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------
  slugify(str){
    return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
  }
  
}
   