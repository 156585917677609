import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // use import {NgIdleModule} from '@ng-idle/core'; if not using keepalive
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import {NgsRevealModule} from 'ngx-scrollreveal';
import 'hammerjs';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutModule } from '@angular/cdk/layout';

import { NavigationService } from './services/navigation.service';


export function createTranslateLoader(http: HttpClient) {
  const url = 'https://cms-iurban.com/langs/';
  return new TranslateHttpLoader(http, url, '.php');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
      BrowserModule, 
      IonicModule.forRoot(), 
      NgIdleKeepaliveModule.forRoot(), 
      GalleryModule, 
      HttpClientModule, 
      NgsRevealModule, 
      AppRoutingModule,
      LayoutModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      })],
    providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
      NavigationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}